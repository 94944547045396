<template>
  <window-content v-if="!loading">
    <div class="m-t">
      <e-row class=" erp-row-col-md">
        <e-col style="max-width: 240px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="110px"
          >
            <erp-input simple-border v-model="reboquista.id" disable />
          </erp-s-field>
        </e-col>

        <e-col style="max-width: 260px">
          <status-select label="Status:" placeholder="Selecione" field-view="lr" simple-border=""
                         v-model="reboquista.status" />
        </e-col>

      </e-row>

      <e-row class=" erp-row-col-md">
        <e-col>
          <erp-s-field
              view="lr"
              :label="reboquista.pessoa.type === 1 ? 'Nome completo:' : 'Razão social:'"
              label-width="110px"
          >
            <erp-input simple-border v-model="reboquista.pessoa.name" disable disabled />
          </erp-s-field>
        </e-col>
      </e-row>
    </div>
    <erp-tabs @change="changeTab" :active="tabActive">
      <erp-tab-item name="principal">Principal</erp-tab-item>
      <erp-tab-item name="reboques" v-if="reboquista.id">Reboques</erp-tab-item>
      <erp-tab-item name="motoristas" v-if="reboquista.id">Motoristas</erp-tab-item>
      <erp-tab-item name="cotacoes" v-if="reboquista.id">Cotações</erp-tab-item>
      <erp-tab-item name="remocoes" v-if="reboquista.id">Remoções</erp-tab-item>
      <!--<erp-tab-item name="planilhas">Planilha de custos</erp-tab-item>-->
      <erp-tab-item name="logs" v-if="reboquista.id">Logs</erp-tab-item>
    </erp-tabs>
    <window-body class="font-11" :class="{'flex col-grow': tabActive === 'logs' || tabActive === 'arremates'}" tabs>
      <div v-show="tabActive === 'principal'" class="wrapper m-t">
        <e-row>
          <e-col>
            <e-row v-if="reboquista.pessoa && reboquista.pessoa.id" mr class="">
              <e-col>
                <prestador-assinatura ref="assinatura" :pessoa="reboquista.pessoa" />
                <div class="m-t-xs">Deixe a assinatura em branco para utilizar a assinatura digital com autenticidade.</div>
              </e-col>
            </e-row>
            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.podeReceberCotacoes">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Pode Receber Cotações</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.atendeQualquerComitente">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Atende qualquer comitente</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>
            <!--<e-row class="" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.permitirCompraBlindado">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Habilitar compra de blindados</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>-->
            <e-row class="m-t-xs" mr>
              <e-col>
                <erp-s-field
                    view="lr"
                    label-width="90px"
                    tag="div"
                >
                  <erp-checkbox class="flex items-center" v-model="reboquista.newsletter">
                    <label class="no-select font-bold">
                      <span class="m-l-xs">Inscrito para newsletter</span>
                    </label>
                  </erp-checkbox>
                </erp-s-field>
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <tipo-comitente-select label-width="90px" label="Cobertura:" field-view="lr" simple-border="" multiple v-model="reboquista.tipoComitenteAtendidos" />
              </e-col>
            </e-row>

            <e-row mr class="m-t">
              <e-col>
                <erp-s-field
                    view="lr"
                    label="Descrição interna:"
                    class="wrap label-wrap"
                >
                  <textarea class="w-full erp-input wrapper-xs" rows="4"
                            v-model="reboquista.observacao"></textarea>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
          <e-col class="m-l-xxl" style="min-width: 216px; max-width: 216px">
            <pessoa-foto ref="foto" :pessoa="reboquista.pessoa" />
          </e-col>
        </e-row>
        <e-row class="m-t">
          <e-col>
            <div class="font-bold m-b-sm">Acesso</div>
            <acesso :entidade="reboquista" />
          </e-col>
        </e-row>
      </div>
      <contato v-show="tabActive === 'contato'" class="wrapper m-t" :entidade="reboquista" />
      <div v-if="reboquista.id" v-show="tabActive === 'reboques'" class="wrapper m-t">
        <reboques :reboquista="reboquista" />
      </div>
      <div v-if="reboquista.id" v-show="tabActive === 'motoristas'" class="wrapper m-t">
        Em desenvolvimento.
      </div>
      <div v-if="reboquista.id" v-show="tabActive === 'cotacoes'" class="wrapper m-t">
        Em desenvolvimento.
      </div>
      <div v-if="reboquista.id" v-show="tabActive === 'remocoes'" class="wrapper m-t">
        Em desenvolvimento.
      </div>
      <!--<div v-show="tabActive === 'planilhas'" class="wrapper m-t"></div>-->
      <div v-if="reboquista.id && tabActive === 'logs'" class="wrapper flex col-grow">
        <log :usuario="reboquista" type="reboquista" />
      </div>
    </window-body>
    <window-footer class="flex content-between">
      <!--<div class="flex items-center justify-center font-11">
        <div class="m-l-xs">
          Ao salvar o leilão ele ficará com status Rascunho. Após validar as informações, altere o
          status para Em breve.
        </div>
      </div>-->
      <div class="inline-block window-actions text-right self-end col-grow">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
/* eslint-disable */
import {
  WindowContent,
  WindowBody,
  WindowFooter,
  ErpTabItem,
  ErpTabs,
  ErpSField,
  ErpInput,
  ErpLabel,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import WindowLoading from '../../../layout/window/Loading'
import {VMoney} from 'v-money'
import {REAL_BRL, convertRealToMoney} from '@/utils/money'
import {
  find,
  newReboquista,
  updateReboquista,
} from '../../../../domain/reboquista/services/index'
import DatetimeInput from '../../../../reuse/input/Datetime'
import DefaultAsyncSelect from '../../../../reuse/input/DefaultAsyncSelect'
import StatusSelect from '../../../arrematante/components/include/StatusArrematanteSelect'
import PatioInput from '../../../patio/helpers/input/PatioInput'
import ERow from '../../../layout/components/Row'
import ECol from '../../../layout/components/Col'
import TipoReboquistaSelect from '../../../arrematante/components/include/TipoArrematanteSelect'
import ApelidoInput from '../../../arrematante/components/include/ApelidoInput'
import Score from '../../../pda/components/apps/arrematante/Score'
import Acesso from '../include/Acesso'
import Log from '../../../usuario/components/include/Log'
import Foto from '../../../arrematante/components/include/Foto'
import Contato from '../../../pessoa/components/include/Contato'

import Reboques from '../include/Reboques'
import TipoComitenteSelect from "@/components/comitente/components/include/TipoComitenteSelect"
import PrestadorAssinatura from "components/vistoriador/components/include/Assinatura.vue";
import PessoaFoto from "components/pessoa/components/include/Foto.vue";

let mockReboquista = {
  id: null,
  apelido: null,
  podeReceberCotacoes: true,
  atendeQualquerComitente: false,
  newsletter: true,
  score: null,
  pjProprietarioCpf: null,
  pjProprietarioNome: null,
  pjProprietarioRg: null,
  pjProprietarioTelefoneComercial: null,
  observacao: null,
  pessoa: {
    id: null,
    name: null,
    document: null,
    type: 1
  },
  enviarBoasVindas: true,
  solicitarCriacaoSenha: true,
  status: 0,
  tipoComitenteAtendidos: null
}

export default {
  name: 'ReboquistaWindow',
  directives: {money: VMoney},
  props: ['id'],
  data () {
    return {
      money: REAL_BRL,
      tabActive: 'principal',
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      deleteFoto: false,
      reboquista: JSON.parse(JSON.stringify(mockReboquista))
    }
  },
  mounted () {
    this.load()
  },
  computed: {
    isPessoaFisica () {
      return this.reboquista.pessoa.type === 1
    },
    isEmpresa () {
      return this.reboquista.pessoa.type === 2
    }
  },
  watch: {},
  methods: {
    load () {
      this.id && find(this.id || this.reboquista.id)
        .then(response => {
          let data = response.data

          if (data.tipoComitenteAtendidos) {
            data.tipoComitenteAtendidos = data.tipoComitenteAtendidos.map(t => t.id)
          }

          this.reboquista = data
          this.$nextTick(() => {
            this.loading = false
          })
        })
        .catch((error) => {
          this.$uloc.dialog({
            title: 'Falha ao carregar!',
            color: 'negative',
            message: `Não conseguimos carregar o arremtante, entre em contato com o administrador.`
          })
          this.loading = false
          this.errors = error.data
          console.error(error, error.data)
        })
    },
    changeTab ({tab}) {
      this.tabActive = tab
    },
    save () {
      console.log('Save')
      this.loading = true
      let data = JSON.parse(JSON.stringify(this.reboquista))

      this.status = 'Processando dados do reboquista'
      let method = data.id ? updateReboquista(data.id, data) : newReboquista(data)
      method
        .then(({data}) => {
          this.reboquista.id = data.id
          const cbSuccess = () => {
            this.success = true
            this.$nextTick(() => {
              // this.$refs.timer.start()
              window.setTimeout(() => {
                this.loading = false
                this.dg()
                this.load()
              }, 3000)
              this.$uloc.window.emit(this.$root.wid, 'update', this.props)
            })
          }
          cbSuccess()

        })
        .catch(error => {
          console.log(error)
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    }
  },
  components: {
    PessoaFoto, PrestadorAssinatura,
    Reboques,
    // Endereco,
    Contato,
    Foto,
    Log,
    Acesso,
    Score,
    ApelidoInput,
    TipoReboquistaSelect,
    ECol,
    ERow,
    PatioInput,
    StatusSelect,
    DefaultAsyncSelect,
    WindowLoading,
    WindowFooter,
    WindowBody,
    WindowContent,
    ErpTabs,
    ErpTabItem,
    ErpSField,
    ErpInput,
    ErpLabel,
    ErpSelect,
    DatetimeInput,
    ErpCheckbox,
    TipoComitenteSelect
  }
}
</script>
